<template>
    <admin-table title="账号设置" @search="handleSearch" :table="table" :loading="loading">
        <div slot="buttons">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">添加账号</el-button>
        </div>
        <el-table-column type="index" width="50" align="center"/>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :key="idx">
        </el-table-column>
        <el-table-column width="270" align="center" label="操作">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                <el-button size="mini" @click="handlePassword(scope.$index, scope.row)">重置密码</el-button>
                <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                            width="180">
                    <p>确定删除[{{scope.row.username}}]的记录吗？</p>
                    <div>
                        <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">确定
                        </el-button>
                        <el-button size="mini" type="default"
                                   @click="$refs[`popover-${scope.$index}`].doClose()">取消
                        </el-button>
                    </div>
                    <el-button size="mini" type="danger" slot="reference">删除</el-button>
                </el-popover>
            </template>
        </el-table-column>
        <el-dialog slot="dialog" title="编辑" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" label-width="100px" style="padding-right: 30px">
                <el-form-item label="用户名">
                    <el-input v-model="form.username" :readonly="!edit_username" :disabled="!edit_username"></el-input>
                </el-form-item>
                <el-form-item label="密码" v-if="edit_password">
                    <el-input v-model="form.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="真实姓名" v-if="edit_phone">
                    <el-input v-model="form.realname"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" v-if="edit_phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="角色" v-if="edit_phone">
                    <el-select v-model="form.role" placeholder="请选择">
                        <el-option v-for="item in roles" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
              </span>
        </el-dialog>
    </admin-table>
</template>


<script>
    export default {
        data() {
            return {
                loading: false,
                roles: [
                    {name: '管理员', value: '管理员'},
                    {name: '手术医生', value: '手术医生'},
                    {name: '手术预约', value: '手术预约'},
                ],
                table: {
                    search: [
                        {
                            type: 'input',
                            key: 'username',
                            placeholder: '请输入关键词'
                        }
                    ],
                    columns: [
                        {title: '用户名', field: 'username', width: '200'},
                        {title: '角色', field: 'role', width: '200'},
                        {title: '真实姓名', field: 'realname', width: '200'},
                        {title: '联系电话', field: 'phone', width: ''},
                    ],
                    data: [],
                    total: 0
                },
                form: {
                    username: '',
                    password: '',
                    phone: '',
                    realname: '',
                    role: '',
                },
                keywords: '',
                dialogVisible: false,
                edit_username: true,
                edit_password: true,
                edit_phone: true,
            }
        },
        methods: {
            async getData(params) {
                this.loading = true
                const resp = await this.$http.get(`/auth/`, {params: params})
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.data
                    this.table.total = resp.data.data.total
                }
                this.loading = false
            },
            async onSubmit() {
                this.loading = true
                let resp
                if (this.form.id) {
                    resp = await this.$http.put(`/auth/${this.form.id}`, this.form)
                } else {
                    resp = await this.$http.post('/auth/', this.form)
                }
                if (resp.data.code != "200") {
                    this.$message.error(resp.data.msg)
                    this.loading = false
                } else {
                    this.getData()
                    this.dialogVisible = false
                }
            },
            handleAdd() {
                this.form = {username: '', password: '', phone: ''}
                this.edit_username = true
                this.edit_password = true
                this.edit_phone = true
                this.dialogVisible = true
            },
            handleEdit(idx, row) {
                this.form = row
                this.edit_username = false
                this.edit_password = false
                this.edit_phone = true
                this.dialogVisible = true
            },
            handlePassword(idx, row) {
                this.form = row
                this.edit_username = false
                this.edit_password = true
                this.edit_phone = false
                this.dialogVisible = true
            },
            async handleDelete(idx, row) {
                this.loading = true
                const resp = await this.$http.delete(`/auth/${row.id}`)
                console.log(resp)
                this.getData()
            },
            handleSearch(e) {
                this.getData(e)
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
</style>
